@tailwind base;
@tailwind components;
@tailwind utilities;

ul {
  list-style-type: disc;
  list-style-position: inside;
  margin: 1rem 0;
}

body {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.bg-hero {
  background: linear-gradient(to bottom right, rgba(229, 246, 255, 0.5), rgba(250, 236, 246, 0.5)),
    url("./assets/images/pattern-1.png");
  background-size: cover;
  background-position: center;
}

/* .bg-pattern {
  background: url('./assets/images/pattern-2.png');
} */

.bg-pattern {
  background: linear-gradient(to bottom right, rgba(229, 246, 255, 0.5), rgba(250, 236, 246, 0.5)),
    url("./assets/images/pattern-2.png");
  background-size: cover;
  background-position: center;
}
